@tailwind base;
@tailwind components;
@tailwind utilities;


.submenu-transition {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  
  .submenu-transition.open {
    max-height: 500px; 
  }
  .frame-custom {
    
    border-style: solid;
    border-width: 24px;
    border-color: transparent; 
    border-top-color: rgba(107, 114, 128, 0.7); 
    border-right-color: rgba(151, 154, 161, 0.7); 
    border-left-color: rgba(237, 237, 237, 0.8); 
    border-bottom-color: rgb(244, 243, 243); 
 
  }
  .underline-decor {
    border-radius: 0.5rem;
    width: 100%;
    height: 4px; 
    margin: 0 auto; 
    margin-top: 8px; 
    background: linear-gradient(90deg, gray 50%, blue 50%); 
    background-size: 200% 100%; 
    background-position: 0% 0%; 
    animation: fillBlue 9s linear infinite; 
  }
  
  @keyframes fillBlue {
    0% {
      background-position: 0% 0%; 
    }
    100% {
      background-position: 100% 0%; 
    }
  }

  .apply-now-btn {
    background: linear-gradient(135deg, #4a90e2, #50e3c2); 
    border: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s; 
    animation: heartbeat 1.5s ease-in-out infinite; 
  }
  
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.1); 
    }
    40% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.1); 
    }
    60% {
      transform: scale(1); 
    }
    100% {
      transform: scale(1);
    }
  }
  
  .apply-now-btn:hover {
    background: linear-gradient(135deg, #50e3c2, #4a90e2); 
    animation: none; 
  }
  .parallax-bg{
    background-image: url('./Images/gracee.jpeg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }